import React from 'react'
import { MansfieldProvider } from '../../context/search-provider'
import Signup from '../../components/mansfield/signup'
import './index.css'
import { Seo } from "../../components/seo/default"

function MansfieldPage () {
    return (
        <React.Fragment>
            <Seo />
            <div className="bg-primary min-h-screen flex justify-center">
                <Signup />
            </div>            
        </React.Fragment>
    )
}

export default function MansfieldPageTemplate(props) {
    return (
        <MansfieldProvider>
            <MansfieldPage {...props} />
        </MansfieldProvider>
    )
}