import React, { useState } from 'react'
import { navigate } from 'gatsby'
import Cookies from 'js-cookie'
import { Logo } from '../../icons'

export default function Signup () {
    const [showModal, setShowModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const [status, setStatus] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [message, setMessage] = useState('')


    const subscribe = async (e) => {
        e.preventDefault()

        try {
            if (email === '') return

            setLoading(true)
            setStatus('')
            setMessage('')

            const url = process.env.NODE_ENV === 'development' ? 'http://localhost:51915/mansfield-customer' : `/.netlify/functions/mansfield-customer`

            const res = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    email: email,
                    accept_marketing: true,
                    tags: 'free-gift-voucher'
                })
            })

            const resData = await res.json()

            if (resData?.errors) {
                throw new Error('Email has already been taken.')
            }
            else {
                setLoading(false)
                setStatus('success')
                setMessage('Thanks for subscribing!')
            }
        } catch (error) {
            console.error(error)
            setLoading(false)
            setStatus('error')
            setMessage(error.message)
        }
    }

    const passwordEnter = () => {
        // const pwd = window.btoa('auricledev')
        const pwd = window.atob("YXVyaWNsZWRldg==")
        // console.log('[pwd]', pwd)
        // console.log('[dwp]', dwp)

        if (password === pwd) {
            Cookies.set('password_entered', 'yes', { expires: 7 })
            navigate('/')
        }
        else {
            setStatus('error')
            setMessage('Incorrect password')
        }
    }

    return (
        <div className="container p-2 md:p-8 text-white">
            <div className="password-header my-8">
                <div className="pwd-header-wrapper">
                    <div className="flex items-center justify-center password-logo">
                        <Logo width={188.6} />
                    </div>
                    <h1 className="text-2xl text-center mt-6 uppercase">MANSFIELD</h1>
                </div>
            </div>
            <div className="password-body flex items-center justify-center">
                <div className="p-2 md:p-8 w-full">
                    <h2 className="text-2xl md:text-4xl text-center uppercase font-normal tracking-widest mb-24">COMING SOON</h2>
                    <div className="password-form mb-16">
                        <form action="/subscribe" method="POST" onSubmit={e => subscribe(e)}>
                            <label htmlFor="email-input" className="uppercase text-base md:text-xl mb-3 block font-normal tracking-wider">Claim My Free £10 Gift Voucher</label>
                            <div className="form-group relative">
                                <input
                                    type="email"
                                    id="email-input"
                                    name="email"
                                    placeholder="MY@EMAIL.COM"
                                    className="rounded-none py-4 px-4 pr-16 md:pr-28 outline-none w-full text-black"
                                    required
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                <button
                                    className={`button uppercase email-submit !absolute right-0 top-0 h-full bg-primary border border-white px-2 md:px-8 tracking-wider ${loading && 'is-loading'}`}
                                >Claim</button>
                            </div>
                            <p className="uppercase text-base md:text-xl mb-3 block font-normal tracking-wider mt-4"></p>
                        </form>
                        {
                            message !== '' && (
                                <div>
                                    {
                                        status === 'success' ? (
                                            <div className="py-2 px-2 bg-green-200">
                                                <span className="text-green-800">{message}</span>
                                            </div>
                                        ) : (
                                            <div className="py-2 px-2 bg-red-200">
                                                <span className="text-red-800">{message}</span>
                                            </div>
                                        )
                                    }
                                </div>
                            )
                        }
                    </div>
                    <div className="social-links w-full mb-8">
                        <ul className="flex items-center justify-around w-full">
                            <li className="social-link">
                                <a href="https://www.instagram.com/Pierceofart">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 24 24" fill="#FFFFFF">
                                        <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"/>
                                    </svg>
                                </a>
                            </li>
                            <li className="social-link">
                                <a href="https://www.facebook.com/Pierceofartuk">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 24 24" fill="#FFFFFF">
                                        <path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z"/>
                                    </svg>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <p className="text-sm leading-7">Your free gift voucher can be redeemed against any item of jewellery in our Mansfield store at the time of your piercing. By claiming your free gift voucher you agree to receive opening updates and marketing emails from Pierce Of Art. Jewellery is charged additionally to our ear piercing service and prices will depend on your chosen piece. Valid until 31 December 2022. Mansfield store only. Full terms will arrive with your voucher code. </p>
                    </div>
                </div>
            </div>

            {
                showModal && (
                    <div className="modal-wrapper fixed top-0 left-0 w-full h-full flex items-center justify-center">
                        <div className="password-input-modal flex items-center justify-center relative">
                            <button className="modal-close-btn text-3xl absolute top-0 right-0 p-8 text-white" onClick={() => setShowModal(false)}>&times;</button>

                            <form action="/password" method="post" className="block w-full" onSubmit={passwordEnter}>
                                <div className="password-input-form py-8 px-4 md:px-16 w-full">
                                    <label htmlFor="password-input" className="text-xl mb-16 block text-center">Enter store using password:</label>
                                    <div className="form-group relative">
                                        <input
                                            type="password"
                                            id="password-input"
                                            name="password"
                                            placeholder="Your Password"
                                            className="rounded-none py-4 px-4 pr-16 md:pr-28 outline-none w-full text-black"
                                            required
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                        />
                                        <button
                                            className={`button uppercase email-submit !absolute right-0 top-0 h-full bg-black border border-white px-2 md:px-8 ${loading && 'is-loading'}`}
                                        >Enter</button>
                                    </div>
                                    {
                                        message !== '' &&  (
                                            <div>
                                                {
                                                    status === 'success' ? (
                                                        <div className="py-2 px-2 bg-green-200">
                                                            <span className="text-green-800">{message}</span>
                                                        </div>
                                                    ) : (
                                                        <div className="py-2 px-2 bg-red-200">
                                                            <span className="text-red-800">{message}</span>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        )
                                    }
                                </div>
                            </form>
                        </div>
                    </div>                    
                )
            }

            {/* <div className="w-full md:w-auto flex items-center justify-center my-8 md:my-0 md:fixed bottom-8 left-0 md:left-auto md:bottom-auto md:top-8 md:right-16">
                <button className="border border-white py-4 px-8" onClick={() => setShowModal(true)}>Enter using password →</button>
            </div> */}
        </div>
    )
}
